import Vue from 'vue';
import API from '../api/index';
import VueRouter from 'vue-router';
import Store from '../store/index';
import Home from '../views/Home.vue';
import RouterContainer from '../views/RouterContainer.vue';
import { $t } from '@/plugins/i18n';
import { BASE_ROOT_URL, WEIXIN_APPID, APP_TITLE } from '@/api/index.js';

Vue.use(VueRouter);

let title = APP_TITLE;
//console.log(title);

const routes = [
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
    name: 'Login',
  },
  {
    path: '/signup',
    component: () => import('@/views/SignUp.vue'),
    name: 'SignUp',
  },
  {
    path: '/',
    name: 'Base',
    component: RouterContainer,
    children: [
      {
        path: '',
        name: 'Home',
        meta: {
          title: title,
        },
        component: Home,
      },
      {
        path: 'orders',
        name: 'Orders',
        meta: {
          title: $t('index.h3erhu'),
        },
        component: () => import('@/views/Orders'),
      },
      {
        path: 'deliver',
        name: 'Deliver',
        meta: {
          keepAlive: true,
        },
        component: () => import('@/views/Deliver'),
      },
      {
        path: 'tracks',
        name: 'Tracks',
        meta: {
          title: $t('查询轨迹'),
        },
        component: () => import('@/views/Tracks'),
      },
      {
        path: 'account',
        name: 'Account',
        meta: {
          title: $t('Profile.个人中心'),
        },
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Account.vue'),
      },
    ],
  },
  {
    path: '/enter-package',
    component: () => import('@/views/package/EnterPackage.vue'),
    name: 'EnterPackage',
    meta: {
      title: $t('EnterPackage.rwdw6u'),
    },
  },
  {
    path: '/transactions',
    component: () => import('@/views/fin/Transactions.vue'),
    name: 'Transactions',
  },
  {
    path: '/charge',
    component: () => import('@/views/Charge.vue'),
    name: 'Charge',
  },
  {
    path: '/chargeResult',
    component: () => import('@/views/ChargeResult.vue'),
    name: 'chargeResult',
  },
  {
    path: '/change-password',
    component: () => import('@/views/ChangePassword.vue'),
    name: 'ChangePassword',
    meta: {
      title: $t('ChangePwd.修改密码'),
    },
  },
  {
    path: '/reset-password',
    component: () => import('@/views/ResetPassword.vue'),
    name: 'ResetPassword',
  },
  {
    path: '/bind-phone',
    component: () => import('@/views/BindPhone.vue'),
    name: 'BindPhone',
  },
  {
    path: '/withdraw',
    component: () => import('@/views/Withdraw.vue'),
    name: 'Withdraw',
  },
  {
    path: '/BankCard',
    component: () => import('@/views/fin/BankCard.vue'),
    name: 'BankCard',
  },
  {
    path: '/transaction-password',
    component: () => import('@/views/fin/TransactionPassword.vue'),
    name: 'TransactionPassword',
  },
  {
    path: '/app-list',
    component: () => import('@/views/ApplicationList.vue'),
    name: 'ApplicationList',
  },
  {
    path: '/customer-service',
    component: () => import('@/views/CustomerService.vue'),
    name: 'CustomerService',
  },
  {
    path: '/address-list',
    component: () => import('@/views/AddressList.vue'),
    name: 'AddressList',
  },
  {
    path: '/address-edit',
    component: () => import('@/views/AddressEdit.vue'),
    name: 'AddressEdit',
    props: route => ({ mode: route.query.mode }),
  },
  {
    path: '/points',
    component: () => import('@/views/MyPoints.vue'),
    name: 'MyPoints',
  },
  {
    path: '/profile',
    component: () => import('@/views/Profile.vue'),
    name: 'Profile',
  },
  {
    path: '/order-query',
    component: () => import('@/views/OrderQuery.vue'),
    name: 'OrderQuery',
    props: route => ({ status: route.query.status }),
  },
  {
    path: '/package/edit',
    component: () => import('@/views/package/PackEdit.vue'),
    name: 'PackageEdit',
  },
  {
    path: '/main-biz',
    component: () => import('@/views/MainBusiness.vue'),
    name: 'MainBusiness',
  },
  {
    path: '/about',
    component: () => import('@/views/About.vue'),
    name: 'About',
  },
  {
    path: '/news',
    component: () => import('@/views/NewsList.vue'),
    name: 'NewsList',
  },
  {
    path: '/news/detail',
    component: () => import('@/views/news/Detail.vue'),
    name: 'NewsDetail',
  },
  {
    path: '/order/detail',
    component: () => import('@/views/order/OrderDetail.vue'),
    name: 'OrderDetail',
  },
  {
    path: '/biz/detail',
    component: () => import('@/views/biz/BizDetail.vue'),
    name: 'BizDetail',
  },
  {
    path: '/service/index',
    component: () => import('@/views/service/MainService.vue'),
    name: 'MainService',
  },
  {
    path: '/service/detail',
    component: () => import('@/views/service/ServiceDetail.vue'),
    name: 'ServiceDetail',
  },
  {
    path: '/mess/list',
    component: () => import('@/views/mess/MessList.vue'),
    name: 'MessList',
  },
  {
    path: '/mess/detail',
    component: () => import('@/views/mess/MessDetail.vue'),
    name: 'MessDetail',
  },
  {
    path: '/notify/list',
    component: () => import('@/views/notify/NotifyList.vue'),
    name: 'NotifyList',
  },
  {
    path: '/notify/detail',
    component: () => import('@/views/notify/NotifyDetail.vue'),
    name: 'NotifyDetail',
  },
  {
    path: '/users/invite',
    component: () => import('@/views/users/Invite.vue'),
    name: 'InviteUser',
  },
  {
    path: '/users/RecStorageAddr',
    component: () => import('@/views/users/RecStorageAddr.vue'),
    name: 'RecStorageAddr',
  },
  {
    path: '/order/list',
    component: () => import('@/views/order/OrderList.vue'),
    name: 'OrderList',
  },
  {
    path: '/order/listprocessing',
    component: () => import('@/views/order/OrderListProcessing.vue'),
    name: 'OrderListProcessing',
  },
  {
    path: '/order/shipping',
    component: () => import('@/views/order/OrderListShipping.vue'),
    name: 'OrderListShipping',
  },
  {
    path: '/order/checked',
    component: () => import('@/views/order/OrderListChecked.vue'),
    name: 'OrderListChecked',
  },
  {
    path: '/package/list',
    component: () => import('@/views/package/PackList.vue'),
    name: 'PackList',
  },
  {
    path: '/company/info',
    component: () => import('@/views/company/FirmDetail.vue'),
    name: 'FirmDetail',
  },
  {
    path: '/company/private',
    component: () => import('@/views/company/PrivatePolicy.vue'),
    name: 'PrivatePolicy',
  },
];

//history --> hash
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {

//   var ua = window.navigator.userAgent.toLowerCase();
//   // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
//   if (ua.indexOf('micromessenger') > -1) {
//     //return true; // 是微信端
//     var targetUri = to.redirect_uri;
//     window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + targetUri + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"

//   } else {
//     //return false;
//   }

//   let token = router.app.$storage.fetch("token");
//   let needAuth = to.matched.some(item => item.meta.login);
//   if (!token && needAuth) {
//     return next({ path: "/login" });
//   }

//   next();

// });

//判断是否在微信客户端打开
function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase();
  var ret = false;
  // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
  if (ua.indexOf('micromessenger') > -1) {
    ret = true; // 是微信端
  }

  return ret;
}

function delCodeandstate(to) {
  //函数作用：去除url中的code和state
  let path = '';
  for (let i in to.query) {
    if (i != 'code' && i != 'state') {
      path = path + '&' + i + '=' + to.query[i];
    }
  }
  path = path == '' ? '' : path.substring(1, path.length);
  path = path == '' ? '' : '/?' + path;
  return path;
}

router.beforeEach((to, from, next) => {
  let fullPath = to.fullPath;
  console.log('-----fullPath:' + fullPath);
  var userItem = sessionStorage.getItem('user');
  if (userItem != null && userItem != '') {
    //console.log('-----已登录')
    return next(); //已经登录
  }

  if (!isWeiXin()) {
    //console.log('----不是在微信中打开')
    return next(); //不是在微信中打开
  }

  if (to.fullPath.includes('code')) {
    //判断url中是否有code，踩坑1-页面反复跳转
    fullPath = delCodeandstate(to);
  }

  let rootUrl = BASE_ROOT_URL;
  console.log(rootUrl);

  let redirect_uri = encodeURIComponent(rootUrl + fullPath),
    appid = WEIXIN_APPID; //redirect_uri，wxa4bd8df67bd14b8e授权登陆后的回调地址，需要进行encodeURIComponent处理
  console.log(appid);
  let code = to.query.code,
    state = to.query.state;
  let noLoginArr = ['/signup', '/login', '/', '/tracks', '/biz', '/company', '/notify', '/service', '/news'],
    isCur = false; //noLoginArr，白名单，不需要授权登陆的页面

  for (let i of noLoginArr) {
    console.log(to.path);
    if (to.path == '/') {
      isCur = true;
    } else if (to.path.startsWith(i) === true) {
      isCur = true;
    }
  }

  if (isCur) {
    //白名单内不做登录判断，直接next
    next();
  } else {
    if (code && state) {
      //登陆之后获取到code，传到后台登录

      //console.log('---begin get Wx user info with code:' + code);
      API.get('/weixin/GetWxUserInfo?code=' + code)
        .then(res => {
          console.log(res);
          if (res.status == 200) {
            var respData = res.data;

            //console.log('respData:' + JSON.stringify(respData));

            var userData = respData.DataDict['user'];

            //console.log('userData:' + JSON.stringify(userData));

            if (userData == null || userData == '') {
              //如果用户没有绑定微信或无账号
              //跳转登录，用户无账号，再从登录页面中点击注册按钮

              var wxid = respData.Content;
              sessionStorage.setItem('LeoStore', wxid);
              return next({ path: '/login' });
            }

            sessionStorage.setItem('user', userData);
            Store.commit('SET_USER_INFO', userData); //设置全局变量
            //this.$store.commit('SET_USER_INFO', userData); //设置全局变量
            //axios.defaults.headers.common['token'] = res.data.token
            next();
          } else if (res.status == 401) {
            //后台判断toke是否失效，失效返回401重新授权登陆
            //去登录
            window.location.href =
              'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
              appid +
              '&redirect_uri=' +
              redirect_uri +
              '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    } /*else if (token) { //已登录，有token，判断是否过期
      axios.defaults.headers.common['token'] = sessionStorage.getItem("token")
      let data = {
        token: token
      }
      axios.post('/api/auth/checkToken', data).then((res) => { //判断token是否过期接口
        if (res.code == 200) {
          next()
        } else if (res.code == 401) { //后台判断toke是否过期，过期返回401重新授权登陆
          sessionStorage.setItem("token", "")
          axios.defaults.headers.common['token'] = ""
          //去登录
          window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" + appid + "&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
        }
      }).catch(function (error) {
      });
    }*/ else {
      //未登录，没有token，去登录
      //去登录
      window.location.href =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
        appid +
        '&redirect_uri=' +
        redirect_uri +
        '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
    }
  }
});

export default router;
