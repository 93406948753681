<template>
  <div id="app" class="flexbox vertical">
    <keep-alive>
      <router-view class="flex-fill" v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view class="flex-fill" v-if="!$route.meta.keepAlive" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 'Home',
    };
  },
  watch: {
    $route(route) {
      this.active = route.name;
    },
  },
  methods: {
    onTabChange(name) {
      this.$router.replace({
        name,
      });
    },
  },
  mounted() {
    this.$store.dispatch('getUserInfo');
  },
};
</script>
<style lang="scss">
@import '~@/styles/_var';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f6f6f6;
  font-size: 14px;
}
html,
body,
#app {
  height: 100%;
}
.title-bar {
  text-align: center;
  font-size: 18px;
  padding: rem(18px) 0;
  background-color: $theme-color;
  color: #fff;
}
.van-tabbar {
  position: relative;
}
</style>
