import Vue from 'vue';
import 'vant/lib/index.css';
import App from './App.vue';
import router from './router';
import store from './store';
import './filters';
import './utils/rem.js';
import { i18n } from '@/plugins/i18n';
import MobilePicker from '@/components/MobilePicker.vue';
import PasswordField from '@/components/PasswordField.vue';
import './styles/common.scss';
import '@/assets/js/drag.js';
import { APP_TITLE } from '@/api/index.js';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
  FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';
library.add(fas, far, fab);

import {
  ActionSheet,
  Button,
  Field,
  Tabbar,
  TabbarItem,
  Tab,
  Tabs,
  Popup,
  Picker,
  Icon,
  Checkbox,
  Pagination,
  RadioGroup,
  Radio,
  Calendar,
  CountDown,
  Search,
  Badge,
  Uploader,
  Form,
  AddressList,
  Swipe,
  SwipeItem,
  Lazyload,
  Cell,
  CellGroup,
  DropdownMenu,
  DropdownItem,
  Grid,
  GridItem,
  Col,
  Row,
  Divider,
} from 'vant';
Vue.config.productionTip = false;

Vue.component('mobile-picker', MobilePicker);
Vue.component('password-field', PasswordField);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);
Vue.use(Field)
  .use(Button)
  .use(Tabs)
  .use(Tab)
  .use(Popup)
  .use(Tabbar)
  .use(Picker)
  .use(Icon)
  .use(Checkbox)
  .use(Pagination)
  .use(RadioGroup)
  .use(Radio)
  .use(Calendar)
  .use(CountDown)
  .use(Search)
  .use(Badge)
  .use(AddressList)
  .use(Form)
  .use(Uploader)
  .use(TabbarItem)
  .use(Swipe)
  .use(SwipeItem)
  .use(Lazyload)
  .use(Cell)
  .use(CellGroup);
Vue.use(ActionSheet);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Col);
Vue.use(Row);
Vue.use(Divider);
new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
}).$mount('#app');

router.beforeEach((to, from, next) => {
  window.document.title =
    to.meta.title == undefined ? APP_TITLE : to.meta.title + '-' + APP_TITLE;

  next();
});
