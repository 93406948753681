import Vue from 'vue';
import { formatDate } from '@/utils/';
Vue.filter('time', (date, format = 'YYYY-MM-DD HH:mm:ss') => {
  if (!date) {
    return '';
  }
  // /Date(1608546900627)/
  if (typeof date === 'string') {
    const m = date.match(/\/Date\((\d{13})\)\//);
    if (m) {
      return formatDate(new Date(+m[1]), format);
    }
  }
  return formatDate(date, format);
});

Vue.filter('empty', value => {
  if (value === 0) {
    return value;
  }
  return value || '-';
});
