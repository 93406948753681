const qs = require('querystring');
import API from './index';

// sel-cateid=0&limit=15&offset=0&order=desc&sort=&sel-status=1&key=
export function getNewsList({
  category,
  limit,
  offset,
  order,
  sort,
  status,
  keyword,
} = {}) {
  return API.get('news/QueryNewsInfoList', {
    params: {
      'sel-cateid': category,
      limit,
      offset,
      order,
      sort,
      'sel-status': status,
      key: keyword,
    },
  }).then(res => res.data);
}

export function queryNews(type, count) {
  return API.post(
    '/news/QueryNews',
    qs.stringify({
      type: type == undefined ? -1 : type,
      count: count == undefined ? 3 : count,
    })
  ).then(res => res.data);
}
export function getNewsCateList() {
  return API.get('/news/getNewsCateList').then(res => res.data);
}

export function getNewsInfo(id) {
  return API.get('/news/GetNewsInfo', {
    params: {
      id,
    },
  }).then(res => res.data);
}

export function getNewsInfoByKey(key) {
  return API.get('/news/GetNewsInfo', {
    params: {
      flag: key,
    },
  }).then(res => res.data);
}
//特色服务
export function getProjectList(maxCount) {
  return API.get('/project/queryProject', {
    params: {
      max: maxCount == undefined ? 6 : maxCount,
    },
  }).then(res => res.data);
}

export function getProjectInfo(id) {
  return API.post(
    '/project/GetProjectByID',
    qs.stringify({
      projectid: id,
    })
  ).then(res => res.data);
}
export function getProjectTypeList() {
  return API.get('/project/GetProjectTypeList').then(res => res.data);
}

//主营业务，
export function getServiceList(maxCount) {
  return API.get('/service/QueryServiceList', {
    params: {
      max: maxCount == undefined ? 6 : maxCount,
    },
  }).then(res => res.data);
}
export function getServiceInfoWithID(id) {
  return API.get('/service/getServiceInfoWithID', {
    params: {
      id: id,
    },
  }).then(res => res.data);
}

export function getLastMess() {
  return API.get('/message/GetLastMess').then(res => res.data);
}

export function queryMessageList(maxCount) {
  return API.get('/message/QueryMessageList', {
    params: {
      max: maxCount == undefined ? 6 : maxCount,
    },
  }).then(res => res.data);
}

export function getMessInfoSetRead(msid) {
  return API.get('/message/getMessInfoSetRead?msid=' + msid).then(
    res => res.data
  );
}

export function getUnReadMsgCount() {
  return API.get('/message/GetUnReadMsgCount').then(res => res.data);
}

export function getLastNotify() {
  return API.get('/notify/GetLastNotify').then(res => res.data);
}

export function getNotifyList(maxCount) {
  return API.get('/notify/GetNotifyList', {
    params: {
      max: maxCount == undefined ? 6 : maxCount,
    },
  }).then(res => res.data);
}

export function getNotifyInfo(id) {
  return API.get('/notify/GetNotifyInfo', {
    params: {
      id: id,
    },
  }).then(res => res.data);
}

export function getFirmInfo() {
  return API.get('/firm/GetFirmInfo').then(res => res.data);
}

export function getSysConf(cfgKey) {
  return API.get('/common/GetSysConf?key=' + cfgKey).then(res => res.data);
}
