const qs = require('querystring');
import API from './index';
import API_Filer from './index';

export function signUp({ username, code, password }) {
  //session中获取微信openid
  var wxid = sessionStorage.getItem('LeoStore');
  if (JSON.stringify(wxid) == '{}') {
    wxid = '';
  }
  return API.post(
    '/customer/Register',
    qs.stringify({
      'f-account': username,
      validateNum: code,
      'f-pwd': password,
      'f-wxid': wxid,
    })
  ).then(res => res.data);

  //todo:注册成功之后自动登录
}

export function signUpWithEmail({ email, password, captcha }) {
  //session中获取微信openid
  var wxid = sessionStorage.getItem('LeoStore');
  if (JSON.stringify(wxid) == '{}') {
    wxid = '';
  }
  // f-emailacc=&email-verify=&f-etagpwd=&
  return API.post('/customer/EmailRegister', null, {
    params: {
      'f-emailacc': email,
      'f-etagpwd': password,
      'email-verify': captcha,
      'f-wxid': wxid,
    },
  }).then(res => res.data);
}

export function login({ username, password }) {
  return API.post('/customer/UserLogin', null, {
    params: {
      tel: username,
      password,
    },
  }).then(res => res.data);
}

export function logout() {
  localStorage.removeItem('token');
  API.defaults.headers.common['Authorization']='';
  return API.post('/customer/UserLogout').then(res => res.data);
}

export function getUserInfo() {
  // var userItem = sessionStorage.getItem('user');
  // if (userItem != null && userItem != '') {
  //   console.log('-----已登录')
  //   return {
  //     Result: true,
  //     DataDict: {
  //       User: userItem,
  //     },
  //   };

  // } else {
  return API.get('/customer/getuserinfo').then(res => res.data).catch(e=>{
    console.log(e);
  });
  //}
}

export function regWeixinAuth() {
  return API.get('/customer/RegWeiXinAuth').then(res => res.data);
}

//删除账户
export function removeAccount(){
  return  API.get('/customer/removeAccount').then(res => res.data);
}

/**
 * 更新用户信息
 * @returns
 */
export function updateUserInfo({
  tel,
  email,
  name,
  bsex,
  province,
  city,
  address,
  files,
}) {
  let formData = new FormData();
  if (files.length > 0) {
    formData.append('f-avatar', files[0].file); //文件上传，这个地方要注意
  }

  formData.append('f-tel', tel);
  formData.append('f-email', email);
  formData.append('f-username', name);
  formData.append('f-agend', bsex);
  formData.append('f-state', province);
  formData.append('f-city', city);
  formData.append('f-addr', address);
  return API_Filer.post('/customer/updateUser', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(res => res.data);
}

export function changePassword({ oldPwd, newPwd }) {
  return API.post('/customer/ModifyPwd', null, {
    params: {
      oldPwd,
      newPwd,
    },
  }).then(res => res.data);
}

export function bindPhone({ tel, code, password, captcha }) {
  return API.post('/customer/DoRebind', null, {
    params: {
      'frm-tel': tel,
      'frm-vercode': code,
      'frm-newpwd': password,
      'frm-imgcode': captcha,
    },
  }).then(res => res.data);
}

export function sendSMSCode({ tel, checkExist = 0 }) {
  return API.post(
    '/customer/getBindValidateNum',
    qs.stringify({
      tel,
      checkexist: checkExist,
    })
  ).then(res => res.data);
}

export function resetPassword({ username, password, code }) {
  return API.post(
    '/customer/ResetPwdByTel',
    qs.stringify({
      fx_loginid: username,
      fx_newpsw: password,
      validateNum: code,
    })
  ).then(res => res.data);
}
export function getAddressList({
  startDate,
  endDate,
  inputSearch,
  limit,
  sort,
  order,
  offset,
}) {
  return API.get('/customer/QueryCustomerAddrList', {
    params: {
      'start-date': startDate,
      'end-date': endDate,
      'input-search': inputSearch,
      limit,
      offset,
      sort,
      order,
    },
  }).then(res => res.data);
}
// receiver=ddd&receive-country=1&receive-province=%E5%B9%BF%E4%B8%9C&receive-city=%E6%B7%B1%E5%9C%B3%E5%B8%82
// &receive-addr=%E7%A6%8F%E7%94%B0%E5%8C%BA&receive-tel=13765556655&receive-zipcode=&receive-email=ggsd%40qq.cc&receive-default=0&receive-desc=&hd-id=24
export function submitAddress({
  id,
  receiver,
  country,
  province,
  city,
  address,
  tel,
  postalCode,
  email,
  isDefault,
} = {}) {
  return API.post(
    '/package/EditReceiver',
    qs.stringify({
      receiver,
      'receive-country': country,
      'receive-province': province,
      'receive-city': city,
      'receive-addr': address,
      'receive-tel': tel,
      'receive-email': email,
      'receive-zipcode': postalCode,
      'receive-default': +isDefault,
      'hd-id': id,
    })
  ).then(res => res.data);
}

export function removeAddress(id) {
  return API.post('/package/DelAddr', null, {
    params: {
      id,
    },
  }).then(res => res.data);
}

/**
 * 查询用户资金账户信息
 */
export function getFinAccInfo() {
  return API.post('/customer/getFinAccInfo', null, {}).then(res => res.data);
}

//查询积分
export function queryPointsForAccount(max) {
  return API.get('/fin/queryPointsForAccount', {
    params: {
      max: max,
    },
  }).then(res => res.data);
}

//获取客户的钱包数据
export function GetWalletInfoByCustID() {
  return API.get('/fin/GetWalletInfoByCustID').then(res => res.data);
}
