import Vue from 'vue';
import VueI18n from 'vue-i18n';
import store from '@/store';
import i18nLabels from '@/i18n';

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: store.getters.language || 'zh', // set locale
  messages: i18nLabels, // set locale messages
});

export function $t(args) {
  return i18n.tc.call(i18n, args);
}
