import axios from 'axios';

// //api的访问url

// export const BASE_URL = 'http://www.tobida88.com';
// export const BASE_ROOT_URL = 'http://m.tobida88.com';
// export const BASE_TMS_URL = 'http://tms.tobida88.com';
// export const WEIXIN_APPID = 'wx8ca44502feeb3a8a';
// export const APP_TITLE = 'TOBIDA国际物流';

// export const BASE_URL = 'http://oms.qianhuicargo.com';
// export const BASE_ROOT_URL = 'http://m.qianhuicargo.com';
// export const BASE_TMS_URL = 'http://tms.qianhuicargo.com';
// export const WEIXIN_APPID = 'wx3b2ceffccd3ddcd9';
// export const APP_TITLE = '千辉国际物流';

export const BASE_URL = process.env.VUE_APP_INTERFACE_URL;
export const BASE_ROOT_URL = process.env.VUE_BASE_ROOT_URL;
export const BASE_TMS_URL = process.env.VUE_BASE_TMS_URL;
export const WEIXIN_APPID = process.env.VUE_WEIXIN_APPID;
export const APP_TITLE = process.env.VUE_APP_TITLE;

//微信公众号的url,rooturl就是自己的
// export const BASE_URL = 'http://www.rising56.com';
// export const BASE_ROOT_URL = 'http://m.rising56.com';
// export const BASE_TMS_URL = 'http://tms.rising56.com';
// export const WEIXIN_APPID = 'wx1cf96ed36909e4e1';
// export const APP_TITLE = '初升国际物流';

// export const BASE_URL = 'http://localhost:8080/api';
// export const BASE_ROOT_URL = 'http://localhost:8080';
// export const BASE_TMS_URL = 'http://localhost:4880';
// export const WEIXIN_APPID = 'wxdf0687546yt8766';
// export const APP_TITLE = '曲速软件';

// export const BASE_URL = 'http://qusuoms.zddexp.com';
// export const BASE_ROOT_URL = 'http://qusuh5.zddexp.com';
// export const BASE_TMS_URL = 'http://qusutms.zddexp.com';
// export const WEIXIN_APPID = 'wxdf0687546yt8766';
// export const APP_TITLE = '曲速测试';

// export const BASE_URL = 'http://gzua.zddexp.com';
// export const BASE_ROOT_URL = 'http://gzua.m.zddexp.com';
// export const BASE_TMS_URL = 'http://tms.youaocargo.com';
// export const WEIXIN_APPID = 'wxdf06831cef06d1f6';
// export const APP_TITLE = '广州优澳';

// export const BASE_URL = 'http://oms.cbr-express.com';
// export const BASE_ROOT_URL = 'http://m.cbr-express.com';
// export const BASE_TMS_URL = 'http://tms.cbr-express.com';
// export const WEIXIN_APPID = 'wxb92e1fd55acdc857';
// export const APP_TITLE = '蓝鲸国际';

// export const BASE_URL = 'http://oms.yc-5566.com';
// export const BASE_ROOT_URL = 'http://oms.m.yc-5566.com';
// export const BASE_TMS_URL = 'http://tms.yc-5566.com';
// export const WEIXIN_APPID = 'wxe40375a957c18e80';
// export const APP_TITLE = '广州扬城';

// export const BASE_URL = 'https://qyoms.zddexp.com';
// export const BASE_ROOT_URL = 'https://qy.m.zddexp.com';
// export const BASE_TMS_URL = 'http://qytms.zddexp.com';
// export const WEIXIN_APPID = 'wxa3cf43817cca782e';
// export const APP_TITLE = '其颖国际';

// export const BASE_URL = 'https://oms.wt6688.cn';
// export const BASE_ROOT_URL = 'https://m.wt6688.cn';
// export const BASE_TMS_URL = 'https://tms.wt6688.cn';
// export const WEIXIN_APPID = 'wx4bb2e0755394f549';
// export const APP_TITLE = '旺泰泰国专线物流';

// export const BASE_URL = 'http://wnoms.zddexp.com';
// export const BASE_ROOT_URL = 'http://wnh5.zddexp.com';
// export const BASE_TMS_URL = 'http://tms.weiningcargo.com';
// export const WEIXIN_APPID = 'wx123f33effecc40b4';
// export const APP_TITLE = '威宁国际';

// export const BASE_URL = 'http://ace.wto-logistics.com.cn';
// export const BASE_ROOT_URL = 'http://ace.m.wto-logistics.com.cn';
// export const BASE_TMS_URL = 'http://aceadmin.wto-logistics.com.cn';
// export const WEIXIN_APPID = 'wxf77a7ad857d857fc';
// export const APP_TITLE = '华雅国际';

// export const BASE_URL = 'http://gzhf.zddexp.com';
// export const BASE_ROOT_URL = 'http://m.gzhf.zddexp.com';
// export const BASE_TMS_URL = 'http://gzmyd.admin.zddexp.com';
// export const WEIXIN_APPID = 'wx9badd1e25b64e7ce';
// export const APP_TITLE = '海风国际';

// export const BASE_URL = 'http://fengniaooms.qsoa.cn';
// export const BASE_ROOT_URL = 'http://m.fengniaooms.qsoa.cn';
// export const BASE_TMS_URL = 'http://fengniaotms.qsoa.cn';
// export const WEIXIN_APPID = 'wx9badd1e25b64e7ce';
// export const APP_TITLE = '峰鸟供应链';

// export const BASE_URL = 'http://gzyh.zddexp.com';
// export const BASE_ROOT_URL = 'http://m.gzyh.zddexp.com';
// export const BASE_TMS_URL = 'http://admin.gzyh.zddexp.com';
// export const WEIXIN_APPID = 'wxaeaed74b82a53c49';
// export const APP_TITLE = '广州粤航';

// export const BASE_URL = 'http://hyoms.zddexp.com';
// export const BASE_ROOT_URL = 'http://huiyao.m.zddexp.com';
// export const BASE_TMS_URL = 'http://hytms.zddexp.com';
// export const WEIXIN_APPID = 'wx640e2eae0bd4c076';
// export const APP_TITLE = '辉耀国际';

// export const BASE_URL = 'http://idaoms.qsoa.cn';
// export const BASE_ROOT_URL = 'http://m.ida.zddexp.com';
// export const BASE_TMS_URL = 'http://idatms.zddexp.com';
// export const WEIXIN_APPID = 'wx640e2eae0bd4c076';
// export const APP_TITLE = '印度专线';

export const API_Filer = axios.create({
  baseURL: `${BASE_URL}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

//華雅wxc838cc41738792ba   wxa4bd8df67bd14b8e
const API = axios.create({
  baseURL: `${BASE_URL}`,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Authorization': localStorage.getItem('token') || '',
  },
});

API.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (typeof response.data === 'string' && response.data.match(/html/i)) {
      location.href = '/login';
    }
    //console.log(response);
    if (
      response.status === 401 ||
      response.status === 404 ||
      response.status === 403
    ) {
      location.href = '/login';
    }
    return response;
  },
  function(error) {
    //var a = JSON.stringify(error);
    // console.log(error);
    // console.log(a);
    if (error.response.status == 404 || error.response.status == 401) {
      location.href = '/login';
    }
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default API;

export function isWeiXin() {
  var ua = window.navigator.userAgent.toLowerCase();
  if (ua.match(/MicroMessenger/i) == 'micromessenger') {
    return true;
  } else {
    return false;
  }
}
