<template>
  <van-field
    :type="showPassword ? 'input' : 'password'"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <template #right-icon>
      <van-icon
        :name="showPassword ? 'closed-eye' : 'eye-o'"
        @click="showPassword = !showPassword"
      />
    </template>
  </van-field>
</template>
<script>
export default {
  data() {
    return {
      showPassword: false,
    };
  },
};
</script>
