<template>
  <div class="mobile-picker" :class="{ border: border, disabled: disabled }">
    <div
      @click="onClick"
      class="picker-text flexbox align-center"
      :class="(current || {}).iconCls"
    >
      <div class="flex-fill inner" :class="{ placeholder: !value }">
        {{ value ? label : placeholder }}
      </div>
      <i class="van-icon van-icon-arrow van-cell__right-icon"></i>
    </div>
    <van-popup
      v-model="showPicker"
      position="bottom"
      :overlay="true"
      round
      get-container="body"
    >
      <van-picker
        :title="title"
        :columns="columns"
        @cancel="onCancel"
        @confirm="onConfirm"
        :disabled="disabled"
        show-toolbar
      />
    </van-popup>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    placeholder: {
      type: String,
      default: '请选择',
    },
    border: {
      type: Boolean,
      default: false,
    },
    valueProp: {
      type: String,
      default: 'value',
    },
    labelProp: {
      type: String,
      default: 'label',
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPicker: false,
      selectedValue: '',
    };
  },
  computed: {
    columns() {
      return this.items.map(item => {
        return {
          text: item[this.labelProp] || item.label || item.name || item.text,
          disabled: item.disabled,
        };
      });
    },
    current() {
      const target = this.items.filter(
        item =>
          (item[this.valueProp] || item.value || item.id) === this.selectedValue
      )[0];
      return target;
    },
    label() {
      const target = this.current;
      if (target) {
        return (
          target[this.labelProp] || target.label || target.text || target.name
        );
      }
      return '';
    },
  },
  watch: {
    value(val) {
      this.selectedValue = val;
    },
  },
  methods: {
    onClick() {
      if (this.disabled) {
        return;
      }
      this.showPicker = true;
    },
    onConfirm(text, index) {
      this.showPicker = false;
      const item = this.items[index];
      this.selectedValue =
        (!item.disabled && (item[this.valueProp] || item.value || item.id)) ||
        '';
      this.$emit('input', this.selectedValue);
      this.$emit('change', this.selectedValue, {
        label: this.label,
        value: this.selectedValue,
      });
    },
    onCancel() {
      this.showPicker = false;
      this.$emit('cancel');
    },
  },
  created() {
    this.selectedValue = this.value;
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/_var';
/deep/ .van-picker-column__item {
  &.van-picker-column__item--selected {
    color: $theme-color;
  }
}
.mobile-picker {
  padding: 6px 14px;
  font-size: 12px;
  &.border {
    border: 1px solid #ebedf2;
    border-radius: 4px;
  }
  &.disabled {
    background-color: #f6f7f9;
    border-color: #e7e9ec;
    color: $theme-color;
  }
  &.dark {
    padding: 5px 16px;
    background-color: rgba(246, 247, 249, 1);
    border-radius: 20px;
  }
  &.darkDisabled {
    background-color: rgba(35, 107, 255, 0.1);
  }
  .placeholder {
    opacity: 0.3;
  }
  /deep/ .van-picker__cancel,
  /deep/ .van-picker__confirm {
    color: $theme-color;
  }
  /deep/ .van-cell__right-icon::before {
    transition: 0.3s;
    transform: rotate(90deg);
  }
}
</style>
