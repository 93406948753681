import Vue from 'vue';
import Vuex from 'vuex';
import { getUserInfo } from '@/api/account';

Vue.use(Vuex);

export default new Vuex.Store({
  getters: {
    userInfo: state => state.userInfo,
  },
  state: {
    userInfo: {},
  },
  mutations: {
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
    },
  },
  actions: {
    getUserInfo(context) {
      getUserInfo().then(res => {
        if (res.Result) {
          context.commit('SET_USER_INFO', res.DataDict.User);
        }else{
          context.commit('SET_USER_INFO', null);
          sessionStorage.removeItem('user');
        }
      });
    },
  },
  modules: {},
});
